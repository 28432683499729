import { tokens } from "@/utils/dummy";
import { formatToK } from "@/utils/numFormatter";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { EmptyState, LoadingRow } from "../common/loading";
import { useSelector } from "react-redux";
import useDebounce from "@/utils/useDebounce";

export interface TokenProps {
  id: number;
  name: string;
  type: string;
  shift: number;
  unit: string;
  price: number;
  image: string;
  volume: number;
  tvl: number;
}

export default function TopTokens() {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const searchQuery = useSelector(
    (state: { common: { searchQuery: string } }) => state.common.searchQuery
  );

  const debouncedValue = useDebounce(searchQuery, 500);

  const [sortOrder, setSortOrder] = useState("desc");

  const [allTokens, setAllTokens] = useState<TokenProps[]>(
    tokens.slice(0, 10).sort((a, b) => b.tvl - a.tvl)
  );

  useEffect(() => {
    if (allTokens && debouncedValue?.length > 0) {
      const tokensFound: TokenProps[] = [];
      allTokens.forEach((tkn) => {
        if (tkn.name.toLowerCase().includes(debouncedValue.toLowerCase())) {
          tokensFound.push(tkn);
        }
      });
      if (tokensFound.length > 0) {
        setAllTokens(tokensFound);
      } else {
        setAllTokens(tokens.slice(0, 10).sort((a, b) => b.tvl - a.tvl));
      }
    }

    if (debouncedValue.length === 0) {
      setAllTokens(tokens.slice(0, 10).sort((a, b) => b.tvl - a.tvl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <section className="mx-auto mt-16 flex w-full max-w-7xl flex-col px-8 lg:px-8">
      <div className="flex w-full justify-between">
        <h2 className="text-xl font-medium text-white">Top Tokens</h2>
        <Link
          href="/tokens"
          className="flex items-center text-sm font-medium text-white/40"
        >
          <span>View all</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>

      <div className="mx-auto w-full">
        <div className="mt-8 flow-root w-full">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block w-full min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative w-full overflow-hidden rounded-lg border-[1px] border-[#1B1B1B] px-2">
                <table className="w-full min-w-full divide-y divide-[#1B1B1B]">
                  <thead className="w-full">
                    <tr className="text-sm font-[300] text-[#9D9D9D]">
                      <th className="w-[50%] border-b-[1px] border-[#1B1B1B] px-3  py-3.5 text-left md:min-w-[12rem] md:pl-10">
                        Name
                      </th>
                      <th className="w-[12%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:text-right">
                        Price
                      </th>
                      <th className="b w-[12%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:text-right">
                        Price change
                      </th>
                      <th className="hidden w-[12%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:text-right lg:table-cell">
                        Volume (24H)
                      </th>
                      <th
                        onClick={() => {
                          if (sortOrder === "desc") {
                            setSortOrder("asc");
                            setAllTokens(
                              allTokens.sort((a, b) => a.tvl - b.tvl)
                            );
                          }
                          if (sortOrder === "asc") {
                            setSortOrder("desc");
                            setAllTokens(
                              allTokens.sort((a, b) => b.tvl - a.tvl)
                            );
                          }
                        }}
                        className="hidden w-[12%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 md:justify-end md:pr-10 md:text-right lg:table-cell"
                      >
                        <div className="flex w-full justify-end">
                          TVL
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-5 w-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-black text-white">
                    {!isLoading &&
                      allTokens.map((token, i) => (
                        <tr
                          key={i}
                          onClick={() => router.push(`/tokens/${token?.id}`)}
                          className="group cursor-pointer px-4"
                        >
                          <td
                            className={
                              "flex flex-col items-start gap-3 whitespace-nowrap rounded-l-[6px] bg-[#151515] px-3 py-4 text-sm font-medium md:pl-10 md:group-hover:bg-[#151515]/50"
                            }
                          >
                            <div className="flex items-center gap-3">
                              <Image
                                src={token?.image}
                                height={30}
                                width={30}
                                quality={100}
                                alt={token?.name}
                                className="object-contain"
                              />
                              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-1">
                                <h3 className="font-medium text-white md:text-base">
                                  {token?.name}
                                </h3>
                                <span className="mt-[0.15rem] text-xs font-[300] text-white/50">
                                  ({token?.type})
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col gap-0 lg:hidden">
                              <span className="whitespace-nowrap bg-[#151515] text-sm font-[300]">
                                Volume: ${formatToK(token?.volume)}
                              </span>
                              <span className="whitespace-nowrap bg-[#151515] text-sm font-[300]">
                                TVL: ${formatToK(token?.tvl)}
                              </span>
                            </div>
                          </td>
                          <td className="whitespace-nowrap bg-[#151515] py-4 text-sm font-[300] group-hover:bg-[#151515]/50 md:px-3 md:text-right">
                            ${formatToK(token?.price)}
                          </td>
                          <td className="whitespace-nowrap rounded-r-[6px] bg-[#151515] py-4 pr-4 text-sm font-[300] group-hover:bg-[#151515]/50 md:px-3 lg:pr-0 md:text-right lg:rounded-r-[0px]">
                            <p
                              className={`flex w-full justify-end self-end text-[14px] md:text-right  ${
                                token?.shift < 0 ? "text-red" : "text-green"
                              }`}
                            >
                              {token?.shift < 0 ? (
                                <span className="flex items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z"
                                      clipRule="evenodd"
                                    />
                                  </svg>

                                  {token?.shift?.toString()?.split("-")[1]}
                                </span>
                              ) : (
                                <span className="flex items-center md:text-right">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  {token?.shift}
                                </span>
                              )}
                              %
                            </p>
                          </td>
                          <td className="hidden whitespace-nowrap bg-[#151515] px-3 py-4 text-sm font-[300] group-hover:bg-[#151515]/50 md:text-right lg:table-cell">
                            ${formatToK(token?.volume)}
                          </td>
                          <td className="hidden whitespace-nowrap rounded-r-[6px] bg-[#151515] px-3 py-4 text-sm font-[300] group-hover:bg-[#151515]/50 md:justify-end md:pr-10 md:text-right lg:table-cell">
                            ${formatToK(token?.tvl)}
                          </td>
                        </tr>
                      ))}
                    {isLoading &&
                      [...Array(5)].map((_, index) => (
                        <LoadingRow colSpan={5} key={index} />
                      ))}
                    {allTokens.slice(0, 10)?.length === 0 && (
                      <EmptyState colSpan={5} message="No tokens available" />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
