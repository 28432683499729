const generateDate = (length = 50): Array<any> => {
    const arr: Array<any> = [];
    const currentDate: Date = new Date();

    for (let i = 0; i < length; i++) {
        const rand1: number = Math.random() * 15 + 5;
        const rand2: number = Math.random() * 15 + 5;

        const date: Date = new Date();
        date.setDate(currentDate.getDate() - i);

        arr.unshift({
            date: formatDate(date),
            index: i,
            spent: Math.ceil(rand1),
            items: Math.ceil(rand2),
        });
    }

    return arr;
};

const formatDate = (date: Date): string => {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = weekdays[date.getDay()];
    const dayOfMonth = String(date.getDate()).padStart(2, '0');

    return `${dayOfWeek} ${dayOfMonth}`;
};

export default generateDate;
