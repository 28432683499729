import { pools } from "@/utils/dummy";
import { formatToK } from "@/utils/numFormatter";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { EmptyState, LoadingRow } from "../common/loading";
import { useSelector } from "react-redux";
import useDebounce from "@/utils/useDebounce";

export interface PoolProps {
  id: number;
  firstToken: { id: number; type: string; image: string };
  secondToken: { id: number; type: string; image: string };
  tvl: number;
  volume: { twentyFourHour: number; sevenDay: number };
}

export default function TopPools() {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const searchQuery = useSelector(
    (state: { common: { searchQuery: string } }) => state.common.searchQuery
  );

  const debouncedValue = useDebounce(searchQuery, 500);

  const [sortOrder, setSortOrder] = useState("desc");

  const [allPools, setAllPools] = useState(
    pools.slice(0, 10).sort((a, b) => b.tvl - a.tvl)
  );

  useEffect(() => {
    if (allPools && debouncedValue?.length > 0) {
      const poolsFound: PoolProps[] = [];
      allPools.forEach((pl) => {
        if (
          pl.firstToken.type
            .toLowerCase()
            .includes(debouncedValue.toLowerCase()) ||
          pl.secondToken.type
            .toLowerCase()
            .includes(debouncedValue.toLowerCase())
        ) {
          poolsFound.push(pl);
        }
      });
      if (poolsFound.length > 0) {
        setAllPools(poolsFound);
      } else {
        setAllPools(pools.slice(0, 10).sort((a, b) => b.tvl - a.tvl));
      }
    }

    if (debouncedValue.length === 0) {
      setAllPools(pools.slice(0, 10).sort((a, b) => b.tvl - a.tvl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <section className="mx-auto mt-16 flex w-full max-w-7xl flex-col px-8 lg:px-8">
      <div className="flex w-full justify-between">
        <h2 className="text-xl font-medium text-white">Top Pools</h2>
        <Link
          href="/pools"
          className="flex items-center text-sm font-medium text-white/40"
        >
          <span>View all</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>

      <div className="">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative overflow-hidden rounded-lg border-[1px] border-[#1B1B1B] px-2">
                <table
                  cellSpacing="0"
                  className="w-full min-w-full table-fixed divide-y divide-[#1B1B1B]"
                >
                  <thead className="w-full">
                    <tr className="text-sm font-[300] text-[#9D9D9D]">
                      <th
                        scope="col"
                        className="md:w-[60%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:pl-10"
                      >
                        Pool
                      </th>
                      <th
                        scope="col"
                        onClick={() => {
                          if (sortOrder === "desc") {
                            setSortOrder("asc");
                            setAllPools(allPools.sort((a, b) => a.tvl - b.tvl));
                          }
                          if (sortOrder === "asc") {
                            setSortOrder("desc");
                            setAllPools(allPools.sort((a, b) => b.tvl - a.tvl));
                          }
                        }}
                        className="table-cell md:w-[13%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:text-right"
                      >
                        <div className="flex w-full justify-end">
                          TVL
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className={`h-5 w-5 transition-all duration-300 ease-in ${
                              sortOrder === "desc" && "rotate-180"
                            }`}
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="hidden w-[13%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:text-right lg:table-cell"
                      >
                        Volume (24H)
                      </th>
                      <th
                        scope="col"
                        className="hidden w-[13%] border-b-[1px] border-[#1B1B1B] px-3 py-3.5 text-left md:justify-end md:pr-10 md:text-right lg:table-cell"
                      >
                        Volume (7D)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full bg-black text-white">
                    {!isLoading &&
                      allPools.map((pool, i) => (
                        <tr
                          onClick={() => router.push(`/pools/${pool?.id}`)}
                          key={i}
                          className="group cursor-pointer px-4"
                        >
                          <td
                            className={
                              "flex flex-col items-start gap-3 whitespace-nowrap rounded-l-[6px] bg-[#151515] px-3 py-4 text-sm font-medium group-hover:bg-[#151515]/50 md:pl-10"
                            }
                          >
                            <div className="flex items-center gap-3">
                              <Image
                                src={pool?.firstToken?.image}
                                height={30}
                                width={30}
                                quality={100}
                                alt={pool?.firstToken?.type}
                                className="object-contain"
                              />
                              <Image
                                src={pool?.secondToken?.image}
                                height={30}
                                width={30}
                                quality={100}
                                alt={pool?.secondToken?.type}
                                className="-ml-4 object-contain"
                              />
                              <h3 className="text-base font-medium text-white">
                                {`${pool?.firstToken?.type}/${pool?.secondToken?.type}`}
                              </h3>
                            </div>
                            <div className="flex flex-col gap-1 lg:hidden">
                              <span className="whitespace-nowrap rounded-r-[6px] bg-[#151515] text-sm font-[300] group-hover:bg-[#151515]/50">
                                24h Volume: $
                                {formatToK(pool?.volume?.twentyFourHour)}
                              </span>
                              <span className="whitespace-nowrap rounded-r-[6px] bg-[#151515] text-sm font-[300] group-hover:bg-[#151515]/50">
                                7d Volume: ${formatToK(pool?.volume?.sevenDay)}
                              </span>
                            </div>
                          </td>
                          <td className="whitespace-nowrap rounded-r-[6px] bg-[#151515] px-3 py-4 pr-4 text-base font-[300] group-hover:bg-[#151515]/50 lg:pr-0 text-right lg:rounded-r-[0px] lg:text-sm">
                            ${formatToK(pool?.tvl)}
                          </td>

                          <td className="hidden whitespace-nowrap bg-[#151515] px-3 py-4 text-sm font-[300] group-hover:bg-[#151515]/50 md:text-right lg:table-cell">
                            ${formatToK(pool?.volume?.twentyFourHour)}
                          </td>
                          <td className="hidden whitespace-nowrap rounded-r-[6px] bg-[#151515] px-3 py-4 text-sm font-[300] group-hover:bg-[#151515]/50 md:justify-end md:pr-10 md:text-right lg:table-cell">
                            ${formatToK(pool?.volume?.sevenDay)}
                          </td>
                        </tr>
                      ))}
                    {isLoading &&
                      [...Array(10)].map((_, index) => (
                        <LoadingRow colSpan={4} key={index} />
                      ))}
                    {allPools?.length === 0 && (
                      <EmptyState colSpan={4} message="No pools available" />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
