import React from "react";
import AreaChart from "./lineChart";

interface TvlChartProps {
  setActiveValue: (val: number) => void;
  resetToLatest: (val: number) => void;
  data: any[];
}

// const data = generateDummyData();

const TvlChart = ({ setActiveValue, resetToLatest, data }: TvlChartProps) => {
  return (
    <AreaChart
      setActiveValue={(val) => setActiveValue(val)}
      colorStop={"#2172E5"}
      data={data}
      resetToLatest={(val: number) => resetToLatest(val)}
    />
  );
};

export default TvlChart;
