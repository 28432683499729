import generateDate from "@/components/charts/dummy";
import FeeGeneratedChart from "@/components/charts/feeGeneratedChart";
import { generateDummyData } from "@/components/charts/lineChart";
import TvlChart from "@/components/charts/tvlChart";
import VolumeChart from "@/components/charts/volumeChart";
import Header from "@/components/common/head";
import Loading from "@/components/common/loading";
import NavBar from "@/components/common/navbar";
import TopPools from "@/components/landing/topPools";
import TopTokens from "@/components/landing/topTokens";
import Trending from "@/components/landing/trending";
import { formatToK } from "@/utils/numFormatter";
import Image from "next/image";
import React, { useState, useEffect } from "react";

const generatedTvlData = generateDummyData();
const generatedFeesData = generateDummyData();
const generatedVolumeData = generateDate(50);

export default function Home() {
  const [loadingTvl, setLoadingTvl] = useState(true);
  const [loadingVolume, setLoadingVolume] = useState(true);
  const [loadingFees, setLoadingFees] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingTvl(false);
      setLoadingVolume(false);
      setLoadingFees(false);
    }, 1000);
  }, []);

  const noDataTvl = false;
  const noDataVolume = false;
  const noDataFees = false;

  const [activeTvl, setActiveTvl] = useState(0);
  const [activeVolume, setActiveVolume] = useState(0);
  const [activeFees, setActiveFees] = useState(0);

  useEffect(() => {
    setActiveTvl(generatedTvlData[generatedTvlData.length - 1]?.tvl);
  }, []);

  useEffect(() => {
    setActiveFees(generatedFeesData[generatedFeesData.length - 1]?.tvl);
  }, []);

  useEffect(() => {
    setActiveVolume(generatedVolumeData[generatedVolumeData.length - 1]?.items);
  }, []);

  return (
    <>
      <Header />
      <main className={`w-full bg-black`}>
        <NavBar />
        <section className="z-40 mx-auto mt-48 w-full max-w-7xl px-4 sm:h-auto md:px-8 2xl:h-auto">
          <div className="relative flex w-full flex-col sm:h-1/2 sm:flex-row">
            <div className="relative flex h-full w-full flex-col pb-8 sm:w-[50%] sm:border-b sm:border-r sm:border-white/40 sm:pr-8 lg:w-[60%]">
              <div className="my-2 flex h-[60px]  w-full flex-col gap-1 px-4 sm:px-0">
                <h2 className="text-xs font-[300] text-white ">
                  Total Value Locked (TVL)
                </h2>
                {loadingTvl || activeTvl === 0 ? (
                  <div className="h-[40px] min-h-[40px] w-[300px] animate-pulse rounded bg-[#2C2C2C]" />
                ) : (
                  <span className="text-3xl font-semibold text-white">
                    ${formatToK(activeTvl)}
                  </span>
                )}
              </div>
              {loadingTvl ? (
                <Loading />
              ) : noDataTvl ? (
                <p className="absolute top-1/2 w-full -translate-y-1/2 text-center text-sm font-medium text-white ">
                  No data available
                </p>
              ) : (
                <TvlChart
                  setActiveValue={(val) => {
                    setActiveTvl(val);
                  }}
                  data={generatedTvlData}
                  resetToLatest={() =>
                    setActiveTvl(
                      generatedVolumeData[generatedVolumeData.length - 1]?.items
                    )
                  }
                />
              )}
            </div>
            <div className="relative flex h-full w-full flex-col pb-8 sm:w-[50%] sm:border-b sm:border-white/40 sm:pl-8 lg:w-[40%]">
              <div className="my-2 flex h-[60px]  w-full flex-col gap-1 px-4 sm:px-0">
                <h2 className="text-xs font-[300] text-white ">
                  Fees generated
                </h2>
                {loadingFees || activeFees === 0 ? (
                  <div className="h-[40px] min-h-[40px] w-[300px] animate-pulse rounded bg-[#2C2C2C]" />
                ) : (
                  <span className="text-3xl font-semibold text-white">
                    ${formatToK(activeFees)}
                  </span>
                )}
              </div>
              {loadingFees ? (
                <Loading />
              ) : noDataFees ? (
                <p className="absolute top-1/2 w-full -translate-y-1/2 text-center text-sm font-medium text-white ">
                  No data available
                </p>
              ) : (
                <FeeGeneratedChart
                  data={generatedFeesData}
                  setActiveValue={(val) => setActiveFees(val)}
                  resetToLatest={() =>
                    setActiveFees(
                      generatedFeesData[generatedFeesData.length - 1]?.tvl
                    )
                  }
                />
              )}
            </div>
            <div className="absolute bottom-[-40px] left-1/2 z-50 hidden h-[80px] w-[80px] -translate-x-1/2 items-center justify-center rounded-full bg-black md:flex lg:left-[59.5%] lg:-translate-x-[40%]">
              <Image
                src={"/svgs/center.svg"}
                height={40}
                width={40}
                quality={100}
                alt={"StarkDefi Analytics"}
                className="object-contain"
              />
            </div>
          </div>
          <div className="flex w-full flex-col pt-8 sm:h-1/2 sm:flex-row">
            <div className="relative flex h-full w-full flex-col justify-end sm:w-[50%] sm:border-r sm:border-white/40 sm:pr-8 sm:pt-4 lg:w-[60%]">
              <div className="flex h-[415px] flex-col justify-between">
                <div className="mb-0 flex h-[60px] w-full flex-col gap-1 px-4 sm:px-0">
                  <h2 className="text-xs font-[300] text-white ">
                    Volume (24h)
                  </h2>
                  {loadingVolume || activeVolume === 0 ? (
                    <div className="h-[40px] min-h-[40px] w-[300px] animate-pulse rounded bg-[#2C2C2C]" />
                  ) : (
                    <span className="text-3xl font-semibold text-white">
                      ${formatToK(activeVolume)}
                    </span>
                  )}
                </div>
                {loadingVolume ? (
                  <Loading />
                ) : noDataVolume ? (
                  <p className="absolute top-1/2 w-full -translate-y-1/2 text-center text-sm font-medium text-white ">
                    No data available
                  </p>
                ) : (
                  <VolumeChart
                    data={generatedVolumeData}
                    resetToLatest={() =>
                      setActiveVolume(
                        generatedVolumeData[generatedVolumeData.length - 1]
                          ?.items
                      )
                    }
                    setActiveValue={(val) => setActiveVolume(val)}
                  />
                )}
              </div>
            </div>
            <div className="relative h-[100px] w-full md:hidden">
              <div className="absolute inset-x-0 top-1/2 h-[1px] w-full -translate-y-1/2 bg-white/40" />
              <div className="absolute left-1/2 top-1/2 z-50 flex h-[80px] w-[80px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-black">
                <Image
                  src={"/svgs/center.svg"}
                  height={40}
                  width={40}
                  quality={100}
                  alt={"StarkDefi Analytics"}
                  className="object-contain"
                />
              </div>
            </div>
            <div className="flex h-full w-full flex-col justify-end self-end sm:w-[50%] sm:pl-6 lg:w-[40%]">
              <Trending />
            </div>
          </div>
        </section>

        <TopTokens />
        <TopPools />
      </main>
    </>
  );
}
