import { tokens } from "@/utils/dummy";
import { formatToK } from "@/utils/numFormatter";
import Image from "next/image";
import React, { useEffect, useState } from "react";

export default function Trending() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="flex h-[415px] flex-col gap-6 px-4 pt-0 md:px-0 md:pl-2">
      <div className="flex gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-trending-up text-red"
        >
          <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
          <polyline points="16 7 22 7 22 13" />
        </svg>
        <h2 className="-mt-1 text-xl font-semibold text-white">Trending</h2>
      </div>
      <div className="flex flex-col gap-4">
        {isLoading && (
          <>
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
            <div className="h-[40px] min-h-[40px] w-full animate-pulse rounded bg-[#2C2C2C]" />
          </>
        )}
        {!isLoading &&
          tokens?.slice(0, 7).map((item, i) => <OneItem key={i} {...item} />)}
        {tokens?.length === 0 && (
          <p className="w-full text-left text-sm font-medium text-white ">
            No data available
          </p>
        )}
      </div>
    </div>
  );
}

function OneItem({
  name,
  type,
  shift,
  unit,
  price,
  image,
}: {
  name: string;
  type: string;
  shift: number;
  unit: string;
  price: number;
  image: string;
}) {
  return (
    <div className="flex md:h-[38px] items-center justify-between">
      <div className="flex h-full gap-4">
        <Image
          src={image}
          height={35}
          width={35}
          quality={100}
          alt={name}
          className="object-contain"
        />
        <div className="flex flex-col gap-0">
          <h3 className="text-base font-semibold text-white">{name}</h3>
          <span className="text-xs font-[300] text-white/50">({type})</span>
        </div>
      </div>

      <div className="flex h-full flex-col">
        <div className="flex font-[300] ">
          <p
            className={`flex text-[16px]  ${
              shift < 0 ? "text-red" : "text-green"
            }`}
          >
            {shift < 0 ? (
              <span className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a.75.75 0 01.75.75v6.638l1.96-2.158a.75.75 0 111.08 1.04l-3.25 3.5a.75.75 0 01-1.08 0l-3.25-3.5a.75.75 0 111.08-1.04l1.96 2.158V5.75A.75.75 0 0110 5z"
                    clipRule="evenodd"
                  />
                </svg>

                {shift.toString().split("-")[1]}
              </span>
            ) : (
              <span className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 15a.75.75 0 01-.75-.75V7.612L7.29 9.77a.75.75 0 01-1.08-1.04l3.25-3.5a.75.75 0 011.08 0l3.25 3.5a.75.75 0 11-1.08 1.04l-1.96-2.158v6.638A.75.75 0 0110 15z"
                    clipRule="evenodd"
                  />
                </svg>
                {shift}
              </span>
            )}
            %
          </p>
          <span className="text-[14px] text-white/50">({unit})</span>
        </div>
        <span className="w-full text-right text-xs font-semibold text-white">
          ${formatToK(price)}
        </span>
      </div>
    </div>
  );
}
