import React from "react";
import AreaChart from "./lineChart";

interface FeeGeneratedChartProps {
  setActiveValue: (val: number) => void;
  resetToLatest: (val: number) => void;
  data: any[];
}

const FeeGeneratedChart = ({
  setActiveValue,
  resetToLatest,
  data,
}: FeeGeneratedChartProps) => {
  return (
    <AreaChart
      setActiveValue={(val) => setActiveValue(val)}
      colorStop={"#7140E4"}
      data={data}
      resetToLatest={(val: number) => resetToLatest(val)}
    />
  );
};

export default FeeGeneratedChart;
