// @ts-nocheck
import React, { useEffect } from "react";

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
// import generateDate from "./dummy";

// const data = generateDate(50);

function VolumeChart({
  setActiveValue,
  resetToLatest,
  data,
}: {
  setActiveValue: (val: number) => void;
  resetToLatest: (val: number) => void;
  data: any[];
}) {
  interface CustomTooltipProps {
    payload: Array<{ payload: { tvl: number } }>;
    active: boolean;
  }

  const CustomTooltip = ({ payload, active }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      setActiveValue(payload[0]?.payload?.items ?? 0);
    }

    return null;
  };

  useEffect(() => {
    resetToLatest(data[data.length - 1]?.items);
    setActiveValue(data[data.length - 1]?.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      className="h-[330px]"
      onMouseLeave={() => resetToLatest(data[data.length - 1]?.items)}
      style={{ width: "100%" }}
    >
      <ResponsiveContainer className="">
        <ComposedChart
          // width={400}
          // height={300}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid stroke="#060606" vertical={false} />
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#060606" }}
            padding={{ left: 20, right: 20 }}
            tick={{
              fontSize: 12,
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            radius={[10, 10, 0, 0]}
            dataKey="items"
            fill="#00DA63"
            yAxisId="left"
            legendType="rect"
            name="Total Volume"
          />
          <YAxis
            tickLine={false}
            yAxisId="left"
            axisLine={{ stroke: "#f5f5f5", strokeWidth: "0.5" }}
            unit="K"
            domain={[5, "dataMax + 5"]}
            tickCount={5}
            hide
          />
          <YAxis
            tickLine={false}
            yAxisId="right"
            orientation="right"
            stroke="#3B7AD9"
            axisLine={{ stroke: "#f5f5f5" }}
            domain={[5, "dataMax + 5"]}
            tickCount={5}
            hide
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VolumeChart;
