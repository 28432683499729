import React from "react";

export default function Loading() {
  return (
    <div className="spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
}

export function LoadingRow({ colSpan }: { colSpan: number }) {
  return (
    <tr className="w-full">
      <td
        colSpan={colSpan}
        className="h-[50px] whitespace-nowrap bg-[#151515] px-4 text-sm text-white first:rounded-l-[6px] last:rounded-r-[6px]"
      >
        <div className="h-[60%] w-full animate-pulse rounded-[15px] bg-[#2C2C2C]" />
      </td>
    </tr>
  );
}

export function EmptyState({
  colSpan,
  message,
}: {
  colSpan: number;
  message: string;
}) {
  return (
    <tr className="px-4">
      <td
        colSpan={colSpan}
        className="flex w-full whitespace-nowrap px-4 py-6 text-sm text-[#9D9D9D]"
      >
        {message}
      </td>
    </tr>
  );
}
